import IconCircle from '../../assets/images/ic-map-circle-hover-blue.svg';
import yellowIconCircle from '../../assets/images/ic-map-circle-hover-yellow.svg';
import nextIcon from '../../assets/images/next-icon.svg';
import previousIcon from '../../assets/images/prev-icon.svg';
import { countries } from './Leaflet.CountrySelect.js';

$(document).ready(function () {
	$('.home--subtitle__category').on('click', function (e) {
		var prevSelected = $('.home--subtitle__category.selected');
		$(prevSelected.data('trigger')).hide();
		prevSelected.removeClass('selected');

		$(e.currentTarget).addClass('selected');
		$(e.currentTarget.dataset.trigger).show();
	});

	$('#home__introduce--thumbnail').on('click', function (e) {
		e.currentTarget.nextElementSibling.style.display = 'block';
		e.currentTarget.style.display = 'none';

		var symbol = $('#YTBvideo')[0].src.indexOf('?') > -1 ? '&' : '?';
		$('#YTBvideo')[0].src += symbol + 'autoplay=1';
	});

	// $(document).on('click', '.barca-banner', function () {
	//   var link = $('#barca-campaign-link').val();
	//   const prefixPath = $('#prefix_path').val();

	//   var target = (prefixPath == 'nft') ? '_self' : '_blank'

	//   if (link.length > 0) {
	//     window.open(link, target).focus()
	//   }
	// })

	$(window).scroll(function () {
		if ($(window).scrollTop() > 300) {
			$('#btn-back-to-top').removeClass('d-none');
		} else {
			$('#btn-back-to-top').addClass('d-none');
		}
	});

	$(document).on('click', '#btn-back-to-top', function (e) {
		e.preventDefault();
		$('html, body').animate({ scrollTop: 0 }, '300');
	});

	let bigMapInstance = null;
	let miniMapInstance = null;
	let currentSelectedRecyclerSlideIndex = 0;
	let currentZoomLevel = null;
	let currentCenter = null;

	const myIcon = L.icon({
		iconUrl: IconCircle,
		iconSize: [38, 38],
		popupAnchor: [0, -10],
		className: 'recycler-icon'
	});

	const myYellowIcon = L.icon({
		iconUrl: yellowIconCircle,
		iconSize: [38, 38],
		popupAnchor: [0, -10],
		className: 'recycler-icon'
	});

	const setAllMapMarkersToDefaultIcon = layer => {
		if (layer.getAllChildMarkers) {
			layer.getAllChildMarkers().forEach(subLayer => {
				if (subLayer.recyclerSlideIndex) {
					subLayer.setIcon(myIcon);
				}
			});
		} else {
			if (layer.recyclerSlideIndex) {
				layer.setIcon(myIcon);
			}
		}
	};

	window.triggerChangeRecyclerSlide = () => {
		$('#recoveryProjectCarousel')
			.find(`.owl-dot:nth-child(${currentSelectedRecyclerSlideIndex})`)
			.trigger('click');
	};

	window.initRecoveryEntitiesMap = async (mapId, isBigMap = false) => {
		let $recyclerCoordinateList = $('#recovery-map-coordinates-list');
		let $recyclerCoordinateBigMap = $('#recovery-map-coordinates-prgs');
		let coordinates = [];
		let hashCoordinates = {};

		try {
			if (isBigMap) {
				coordinates = JSON.parse(
					$recyclerCoordinateBigMap
						.val()
						.replace(/\n/g, '\\n')
						.replace(/\r/g, '\\r')
						.replace(/\t/g, '\\t')
				);
			} else {
				coordinates = JSON.parse(
					$recyclerCoordinateList
						.val()
						.replace(/\n/g, '\\n')
						.replace(/\r/g, '\\r')
						.replace(/\t/g, '\\t')
				);
			}
		} catch {
			coordinates = [];
		}

		const bounds = new L.LatLngBounds(
			new L.LatLng(85.0, -180.0),
			new L.LatLng(-85.0, 180.0)
		);
		const map = L.map(mapId, {
			center: bounds.getCenter(),
			zoom: isBigMap ? 2 : 1,
			trackResize: false,
			tap: false,
			maxBound: bounds,
			maxBoundsViscosity: 1.0,
			fillColor: '#94AAFA',
			layers: [
				L.tileLayer(
					'https://api.mapbox.com/styles/v1/garciapd/clrkjctdu002401pjc8h3213n/tiles/{tileSize}/{z}/{x}/{y}@2x?access_token={accessToken}',
					{
						minZoom: 1,
						id: 'mapbox/streets-v11',
						tileSize: 512,
						zoomOffset: -1,
						fillColor: '#94AAFA',
						type: 'fill',
						accessToken:
							'pk.eyJ1IjoiZ2FyY2lhcGQiLCJhIjoiY2w0cnd1dXk2MGcxYjNpbzB2NnViM3VudyJ9.t7T0_cx2_51iTXSUv9LiYA',
						paint: {
							'fill-color': '#94AAFA'
						}
					}
				)
			]
		});

		if (isBigMap) {
			bigMapInstance = map;
		} else {
			miniMapInstance = map;
		}

		map.on('drag', function () {
			map.panInsideBounds(bounds, { animate: false });
		});

		let markers = L.markerClusterGroup({
			showCoverageOnHover: false,
			iconCreateFunction: function (cluster) {
				const html = `
          <div class="cluster-markers-group-content">
            <span>${cluster.getChildCount()}</span>
            <img src="${IconCircle}">
          </div>
        `;
				return L.divIcon({
					html,
					className: 'prg-map-cluster-markers-group-icon',
					iconSize: L.point(40, 40),
					iconAnchor: [25, 25]
				});
			}
		});

		let recyclerSlideIndex = 0;

		const showRandomPopupId = Math.floor(Math.random() * coordinates.length);
		for (let i = 0; i < coordinates.length; i++) {
			if (!coordinates[i]) continue;
			recyclerSlideIndex += 1;

			if (coordinates[i][1] && coordinates[i][1].length === 2) {
				let marker = L.marker(coordinates[i][1], { icon: myIcon });
				marker.recyclerSlideIndex = recyclerSlideIndex;
				hashCoordinates[recyclerSlideIndex] = coordinates[i][1];
				if (isBigMap) {
					let profileImages = [];
					profileImages = coordinates[i][3][4];
					let carouselHtml = '';
					profileImages.forEach(element => {
						carouselHtml += `<div class="owl-item">
                                <img class='lazyload' src=${element} />
                              </div>`;
					});

					let rpIconsList = '';
					if (coordinates[i][6]) {
						coordinates[i][6].forEach((element, i) => {
							rpIconsList += `<img class='rp-item' src='/assets/map/ic-${element}.svg' data-toggle='tooltip' data-placement='top' data-html='true' title='${
								element.charAt(0).toUpperCase() + element.slice(1)
							}' style='z-index: ${5 - i}'/>`;
						});
					}

					marker
						.bindPopup(
							`<div class="map__pop-up">
                              <div class='map__pop-up__banner-image'>
                                <div class="main__carousel explore-recovery-project-profile-images owl-carousel owl-loaded" id="${
																	coordinates[i][3][0]
																}exploreRecoveryProjectProfileImages">
                                  <div class="background-gradient"></div>
                                  <div class="owl-stage-outer owl-height">
                                    <div class="owl-stage">
                                      ${carouselHtml}
                                    </div>
                                  </div>
                                  <button class="${
																		profileImages.length == 1 ? 'hide' : ''
																	} home__nav home__nav--prev" data-nav="#${
								coordinates[i][3][0]
							}exploreRecoveryProjectProfileImages" type="button">
                                    <img class='' src=${previousIcon} />
                                  </button>
                                  <button class="${
																		profileImages.length == 1 ? 'hide' : ''
																	} home__nav home__nav--next" data-nav="#${
								coordinates[i][3][0]
							}exploreRecoveryProjectProfileImages" type="button">
                                    <img class='' src=${nextIcon} />
                                  </button>
                                </div>
                              </div>
                              <div class="map__pop-up__title">
                                <div class='map__pop-up__title__recovert-project-info'>
                                  <div class="img">
                                    <a href='/users/${coordinates[i][3][0]}'>
                                      <img class='' src=${
																				coordinates[i][3][2]
																			} />
                                    </a>
                                  </div>
                                  <div class="content">
                                    <div class='map__pop-up__name font-weight-600-text text-truncate-1'>
                                      <a href='/users/${coordinates[i][3][0]}'>
                                        ${coordinates[i][3][1]}
                                      </a>
                                    </div>
                                    <span class="map__pop-up__description text-truncate-1 neutral-500-color-text">
                                      <i class='${coordinates[i][4]}'></i>
                                      ${coordinates[i][0]}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div class="map__pop-up__rp-list-ic">
                                <div class="label-name">Recovers plastic from:</div>
                                <div class="list">${rpIconsList}</div>
                              </div>
                            </div>`,
							{
								maxWidth: 500,
								closeButton: false
							}
						)
						.openPopup();
				}
				markers.addLayer(marker);
				window.addFlagIconsCdnLink();
			}
		}

		markers.on('click', a => {
			map.eachLayer(l => {
				setAllMapMarkersToDefaultIcon(l);
			});

			currentZoomLevel = map.getZoom();
			currentCenter = a.layer.getLatLng();
			a.layer.setIcon(myYellowIcon);
			window.addFlagIconsCdnLink();

			currentSelectedRecyclerSlideIndex = a.layer.recyclerSlideIndex;

			if (!isBigMap) {
				window.triggerChangeRecyclerSlide();
			}
			if (isBigMap) {
				console.log('init slide');
				window.initExploreRecoveryProjectImagesCarousel();
				window.addFlagIconsCdnLink();
			}
		});

		if (!isBigMap) {
			$('.main__carousel--recovery-project').on(
				'initialized.owl.carousel changed.owl.carousel',
				function (event) {
					let pageIndex = Math.max(event.page.index + 1, 1);
					currentSelectedRecyclerSlideIndex = pageIndex;
					currentCenter = hashCoordinates[pageIndex];
					miniMapInstance.eachLayer(l => {
						setAllMapMarkersToDefaultIcon(l);
					});
					triggerUpdateCenterForMiniMap();
					triggerUpdateRecyclerPointForMiniMap(markers);
				}
			);
		}

		const countriesList = JSON.parse($('#countries-list').val());
		var features = countriesList
			.map(countryName => countries[countryName])
			.filter(element => element != undefined);

		var countriesData = {
			type: 'FeatureCollection',
			features: features
		};

		L.geoJSON(countriesData, {
			style: function (feature) {
				return {
					fillColor: '#0A3CEB',
					weight: 0,
					opacity: 1,
					fillOpacity: 1
				};
			}
		}).addTo(map);

		// Show a popup for the random marker when the map is clicked

		map.addLayer(markers);
		map.touchZoom.disable();
		map.doubleClickZoom.disable();
		map.scrollWheelZoom.disable();
		map.boxZoom.disable();
		map.keyboard.disable();
		map.dragging.disable();

		$(`#${mapId}`).on('click', () => {
			map.dragging.enable();
			map.touchZoom.enable();
			map.doubleClickZoom.enable();
			map.scrollWheelZoom.enable();
			map.boxZoom.enable();
			map.keyboard.enable();
		});

		if ($.magnificPopup.instance.isOpen) {
			map.on('dragend', function () {
				$.magnificPopup.open({
					closeOnBgClick: true,
					enableEscapeKey: false,
					items: {
						src: '#recovery-project-show-map-popup'
					},
					type: 'inline',
					callbacks: {
						close: () => {
							window.triggerChangeRecyclerSlide();
							// window.triggerUpdateRecyclerPointForMiniMap();
						}
					}
				});
			});
		}

		map.eachLayer(l => {
			if (l.getAllChildMarkers) {
				l.getAllChildMarkers().forEach(subLayer => {
					if (subLayer.recyclerSlideIndex == showRandomPopupId) {
						subLayer.setIcon(myYellowIcon);
						console.log(subLayer.recyclerSlideIndex);
						currentCenter = subLayer.getLatLng();
						markers.zoomToShowLayer(subLayer, function (e) {
							subLayer.fire('click');
							window.initExploreRecoveryProjectImagesCarousel();
							window.triggerChangeRecyclerSlide();
							window.addFlagIconsCdnLink();
						});
					}
				});
			} else if (l.recyclerSlideIndex == showRandomPopupId) {
				l.setIcon(myYellowIcon);
				currentCenter = l.getLatLng();
				window.addFlagIconsCdnLink();
				l.fire('click');
			}
		});
	};

	if ($('#recovery-project-big-map').length > 0) {
		initRecoveryEntitiesMap('recovery-project-big-map', true);
	}

	//numbers animation
	let currNum;
	let ticks;
	let fresh = true;
	const time = 1000;

	function scrollNumber(digits, stat) {
		stat.querySelectorAll('span[data-value]').forEach((tick, i) => {
			let value = 100 * parseInt(digits[i]);
			if (isNaN(value)) {
				value = 1000;
			}
			tick.style.transform = `translateY(-${value}%)`;
		});

		// setTimeout(() => {
		//   stat.style.width = `${digits.length * 28}px`;
		// }, 100)
	}

	function addDigit(digit, fresh, stat) {
		let spanList = Array(10)
			.join(0)
			.split(0)
			.map((x, j) => `<span>${j}</span>`)
			.join('');
		spanList += `<span class="comma">,</span>`;

		stat.insertAdjacentHTML(
			'beforeend',
			`<span style="transform: translateY(0%)" data-value="${digit}">
        ${spanList}
      </span>`
		);

		const firstDigit = stat.lastElementChild;

		setTimeout(
			() => {
				if (digit == ',') {
					firstDigit.classList.add('comma');
				}

				firstDigit.classList.add('visible');
			},
			fresh ? 0 : 2000
		);
	}
	function setup(startNum, stat) {
		const digits = startNum.toString().split('');

		for (let i = 0; i < digits.length; i++) {
			addDigit(digits[i], true, stat);
		}

		setTimeout(() => scrollNumber(digits, stat), 100);
		currNum = startNum;
	}

	let scrolledToNumberRecovered = false;
	$(window).scroll(function () {
		if ($('#number-recovered').length) {
			var hT = $('#number-recovered').offset().top,
				hH = $('#number-recovered').outerHeight(),
				wH = $(window).height(),
				wS = $(this).scrollTop();
			if (wS > hT + hH - wH - 50 && !scrolledToNumberRecovered) {
				scrolledToNumberRecovered = true;
				const stat = document.querySelector('#number-recovered');
				const stat2 = document.querySelector('#number-recovered-convert');
				setup(stat.getAttribute('data-quantity'), stat);
				setup(stat2.getAttribute('data-quantity'), stat2);
			}
		}
	});

	$('#close-banner').on('click', function (e) {
		e.preventDefault();
		e.stopImmediatePropagation();
		$(this).parent().remove();
		return false;
	});
});
